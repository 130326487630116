export default {
    "name": "CollectionSliderQuery",
    "kind": "HoudiniQuery",
    "hash": "5e08a112f96db04fb423da75a81d4342695db95262660394c9c623edfe885554",

    "raw": `query CollectionSliderQuery($id: ID, $handle: String, $after: String, $country: CountryCode!, $language: LanguageCode!, $productCount: Int = 250) @inContext(country: $country, language: $language) {
  collection(id: $id, handle: $handle) {
    id
    handle
    description
    descriptionHtml
    title
    image {
      src
      altText
      id
    }
    includeInMenu: metafield(key: "include_in_menu", namespace: "sprenger") {
      key
      value
      id
    }
    products(first: $productCount, after: $after) {
      edges {
        node {
          id
          handle
          description
          availableForSale
          priceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          compareAtPriceRange {
            maxVariantPrice {
              amount
              currencyCode
            }
            minVariantPrice {
              amount
              currencyCode
            }
          }
          colormap: metafield(key: "colormap", namespace: "sprenger") {
            key
            value
            id
          }
          sku: metafield(key: "just_sku", namespace: "sprenger") {
            key
            value
            id
          }
          imageUrls: metafield(key: "image_urls", namespace: "sprenger") {
            key
            value
            id
          }
          dreid: metafield(key: "dreid", namespace: "sprenger") {
            key
            value
            id
          }
          video: metafield(key: "video", namespace: "sprenger") {
            key
            value
            id
          }
          highlights: metafield(key: "highlights", namespace: "sprenger") {
            key
            value
            id
          }
          collections(first: 100) {
            edges {
              node {
                title
                description
                path: metafield(key: "path", namespace: "sprenger") {
                  key
                  value
                  id
                }
                id
              }
            }
          }
          variants(first: 10) {
            edges {
              node {
                id
                technical_data: metafield(key: "technical_data", namespace: "sprenger") {
                  key
                  value
                  id
                }
                isArchived: metafield(key: "is_archived", namespace: "sprenger") {
                  key
                  value
                  id
                }
                sku: metafield(key: "just_sku", namespace: "sprenger") {
                  key
                  value
                  id
                }
                quantityAvailable
                price {
                  amount
                }
                compareAtPrice {
                  amount
                }
              }
            }
          }
          title
          tags
          totalInventory
          publishedAt
        }
        cursor
      }
      pageInfo {
        hasNextPage
      }
    }
  }
}
`,

    "rootType": "QueryRoot",

    "selection": {
        "fields": {
            "collection": {
                "type": "Collection",
                "keyRaw": "collection(handle: $handle, id: $id)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "ID",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "handle": {
                            "type": "String",
                            "keyRaw": "handle",
                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "visible": true
                        },

                        "descriptionHtml": {
                            "type": "HTML",
                            "keyRaw": "descriptionHtml",
                            "visible": true
                        },

                        "title": {
                            "type": "String",
                            "keyRaw": "title",
                            "visible": true
                        },

                        "image": {
                            "type": "Image",
                            "keyRaw": "image",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "src": {
                                        "type": "URL",
                                        "keyRaw": "src",
                                        "visible": true
                                    },

                                    "altText": {
                                        "type": "String",
                                        "keyRaw": "altText",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true,
                                        "nullable": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "includeInMenu": {
                            "type": "Metafield",
                            "keyRaw": "includeInMenu(key: \"include_in_menu\", namespace: \"sprenger\")",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "key": {
                                        "type": "String",
                                        "keyRaw": "key",
                                        "visible": true
                                    },

                                    "value": {
                                        "type": "String",
                                        "keyRaw": "value",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "ID",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "products": {
                            "type": "ProductConnection",
                            "keyRaw": "products(after: $after, first: $productCount)",

                            "selection": {
                                "fields": {
                                    "edges": {
                                        "type": "ProductEdge",
                                        "keyRaw": "edges",

                                        "selection": {
                                            "fields": {
                                                "node": {
                                                    "type": "Product",
                                                    "keyRaw": "node",

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "ID",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "handle": {
                                                                "type": "String",
                                                                "keyRaw": "handle",
                                                                "visible": true
                                                            },

                                                            "description": {
                                                                "type": "String",
                                                                "keyRaw": "description",
                                                                "visible": true
                                                            },

                                                            "availableForSale": {
                                                                "type": "Boolean",
                                                                "keyRaw": "availableForSale",
                                                                "visible": true
                                                            },

                                                            "priceRange": {
                                                                "type": "ProductPriceRange",
                                                                "keyRaw": "priceRange",

                                                                "selection": {
                                                                    "fields": {
                                                                        "maxVariantPrice": {
                                                                            "type": "MoneyV2",
                                                                            "keyRaw": "maxVariantPrice",

                                                                            "selection": {
                                                                                "fields": {
                                                                                    "amount": {
                                                                                        "type": "Decimal",
                                                                                        "keyRaw": "amount",
                                                                                        "visible": true
                                                                                    },

                                                                                    "currencyCode": {
                                                                                        "type": "CurrencyCode",
                                                                                        "keyRaw": "currencyCode",
                                                                                        "visible": true
                                                                                    }
                                                                                }
                                                                            },

                                                                            "visible": true
                                                                        },

                                                                        "minVariantPrice": {
                                                                            "type": "MoneyV2",
                                                                            "keyRaw": "minVariantPrice",

                                                                            "selection": {
                                                                                "fields": {
                                                                                    "amount": {
                                                                                        "type": "Decimal",
                                                                                        "keyRaw": "amount",
                                                                                        "visible": true
                                                                                    },

                                                                                    "currencyCode": {
                                                                                        "type": "CurrencyCode",
                                                                                        "keyRaw": "currencyCode",
                                                                                        "visible": true
                                                                                    }
                                                                                }
                                                                            },

                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "compareAtPriceRange": {
                                                                "type": "ProductPriceRange",
                                                                "keyRaw": "compareAtPriceRange",

                                                                "selection": {
                                                                    "fields": {
                                                                        "maxVariantPrice": {
                                                                            "type": "MoneyV2",
                                                                            "keyRaw": "maxVariantPrice",

                                                                            "selection": {
                                                                                "fields": {
                                                                                    "amount": {
                                                                                        "type": "Decimal",
                                                                                        "keyRaw": "amount",
                                                                                        "visible": true
                                                                                    },

                                                                                    "currencyCode": {
                                                                                        "type": "CurrencyCode",
                                                                                        "keyRaw": "currencyCode",
                                                                                        "visible": true
                                                                                    }
                                                                                }
                                                                            },

                                                                            "visible": true
                                                                        },

                                                                        "minVariantPrice": {
                                                                            "type": "MoneyV2",
                                                                            "keyRaw": "minVariantPrice",

                                                                            "selection": {
                                                                                "fields": {
                                                                                    "amount": {
                                                                                        "type": "Decimal",
                                                                                        "keyRaw": "amount",
                                                                                        "visible": true
                                                                                    },

                                                                                    "currencyCode": {
                                                                                        "type": "CurrencyCode",
                                                                                        "keyRaw": "currencyCode",
                                                                                        "visible": true
                                                                                    }
                                                                                }
                                                                            },

                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "colormap": {
                                                                "type": "Metafield",
                                                                "keyRaw": "colormap(key: \"colormap\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "sku": {
                                                                "type": "Metafield",
                                                                "keyRaw": "sku(key: \"just_sku\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "imageUrls": {
                                                                "type": "Metafield",
                                                                "keyRaw": "imageUrls(key: \"image_urls\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "dreid": {
                                                                "type": "Metafield",
                                                                "keyRaw": "dreid(key: \"dreid\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "video": {
                                                                "type": "Metafield",
                                                                "keyRaw": "video(key: \"video\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "highlights": {
                                                                "type": "Metafield",
                                                                "keyRaw": "highlights(key: \"highlights\", namespace: \"sprenger\")",
                                                                "nullable": true,

                                                                "selection": {
                                                                    "fields": {
                                                                        "key": {
                                                                            "type": "String",
                                                                            "keyRaw": "key",
                                                                            "visible": true
                                                                        },

                                                                        "value": {
                                                                            "type": "String",
                                                                            "keyRaw": "value",
                                                                            "visible": true
                                                                        },

                                                                        "id": {
                                                                            "type": "ID",
                                                                            "keyRaw": "id",
                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "collections": {
                                                                "type": "CollectionConnection",
                                                                "keyRaw": "collections(first: 100)",

                                                                "selection": {
                                                                    "fields": {
                                                                        "edges": {
                                                                            "type": "CollectionEdge",
                                                                            "keyRaw": "edges",

                                                                            "selection": {
                                                                                "fields": {
                                                                                    "node": {
                                                                                        "type": "Collection",
                                                                                        "keyRaw": "node",

                                                                                        "selection": {
                                                                                            "fields": {
                                                                                                "title": {
                                                                                                    "type": "String",
                                                                                                    "keyRaw": "title",
                                                                                                    "visible": true
                                                                                                },

                                                                                                "description": {
                                                                                                    "type": "String",
                                                                                                    "keyRaw": "description",
                                                                                                    "visible": true
                                                                                                },

                                                                                                "path": {
                                                                                                    "type": "Metafield",
                                                                                                    "keyRaw": "path(key: \"path\", namespace: \"sprenger\")",
                                                                                                    "nullable": true,

                                                                                                    "selection": {
                                                                                                        "fields": {
                                                                                                            "key": {
                                                                                                                "type": "String",
                                                                                                                "keyRaw": "key",
                                                                                                                "visible": true
                                                                                                            },

                                                                                                            "value": {
                                                                                                                "type": "String",
                                                                                                                "keyRaw": "value",
                                                                                                                "visible": true
                                                                                                            },

                                                                                                            "id": {
                                                                                                                "type": "ID",
                                                                                                                "keyRaw": "id",
                                                                                                                "visible": true
                                                                                                            }
                                                                                                        }
                                                                                                    },

                                                                                                    "visible": true
                                                                                                },

                                                                                                "id": {
                                                                                                    "type": "ID",
                                                                                                    "keyRaw": "id",
                                                                                                    "visible": true
                                                                                                }
                                                                                            }
                                                                                        },

                                                                                        "visible": true
                                                                                    }
                                                                                }
                                                                            },

                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "variants": {
                                                                "type": "ProductVariantConnection",
                                                                "keyRaw": "variants(first: 10)",

                                                                "selection": {
                                                                    "fields": {
                                                                        "edges": {
                                                                            "type": "ProductVariantEdge",
                                                                            "keyRaw": "edges",

                                                                            "selection": {
                                                                                "fields": {
                                                                                    "node": {
                                                                                        "type": "ProductVariant",
                                                                                        "keyRaw": "node",

                                                                                        "selection": {
                                                                                            "fields": {
                                                                                                "id": {
                                                                                                    "type": "ID",
                                                                                                    "keyRaw": "id",
                                                                                                    "visible": true
                                                                                                },

                                                                                                "technical_data": {
                                                                                                    "type": "Metafield",
                                                                                                    "keyRaw": "technical_data(key: \"technical_data\", namespace: \"sprenger\")",
                                                                                                    "nullable": true,

                                                                                                    "selection": {
                                                                                                        "fields": {
                                                                                                            "key": {
                                                                                                                "type": "String",
                                                                                                                "keyRaw": "key",
                                                                                                                "visible": true
                                                                                                            },

                                                                                                            "value": {
                                                                                                                "type": "String",
                                                                                                                "keyRaw": "value",
                                                                                                                "visible": true
                                                                                                            },

                                                                                                            "id": {
                                                                                                                "type": "ID",
                                                                                                                "keyRaw": "id",
                                                                                                                "visible": true
                                                                                                            }
                                                                                                        }
                                                                                                    },

                                                                                                    "visible": true
                                                                                                },

                                                                                                "isArchived": {
                                                                                                    "type": "Metafield",
                                                                                                    "keyRaw": "isArchived(key: \"is_archived\", namespace: \"sprenger\")",
                                                                                                    "nullable": true,

                                                                                                    "selection": {
                                                                                                        "fields": {
                                                                                                            "key": {
                                                                                                                "type": "String",
                                                                                                                "keyRaw": "key",
                                                                                                                "visible": true
                                                                                                            },

                                                                                                            "value": {
                                                                                                                "type": "String",
                                                                                                                "keyRaw": "value",
                                                                                                                "visible": true
                                                                                                            },

                                                                                                            "id": {
                                                                                                                "type": "ID",
                                                                                                                "keyRaw": "id",
                                                                                                                "visible": true
                                                                                                            }
                                                                                                        }
                                                                                                    },

                                                                                                    "visible": true
                                                                                                },

                                                                                                "sku": {
                                                                                                    "type": "Metafield",
                                                                                                    "keyRaw": "sku(key: \"just_sku\", namespace: \"sprenger\")",
                                                                                                    "nullable": true,

                                                                                                    "selection": {
                                                                                                        "fields": {
                                                                                                            "key": {
                                                                                                                "type": "String",
                                                                                                                "keyRaw": "key",
                                                                                                                "visible": true
                                                                                                            },

                                                                                                            "value": {
                                                                                                                "type": "String",
                                                                                                                "keyRaw": "value",
                                                                                                                "visible": true
                                                                                                            },

                                                                                                            "id": {
                                                                                                                "type": "ID",
                                                                                                                "keyRaw": "id",
                                                                                                                "visible": true
                                                                                                            }
                                                                                                        }
                                                                                                    },

                                                                                                    "visible": true
                                                                                                },

                                                                                                "quantityAvailable": {
                                                                                                    "type": "Int",
                                                                                                    "keyRaw": "quantityAvailable",
                                                                                                    "nullable": true,
                                                                                                    "visible": true
                                                                                                },

                                                                                                "price": {
                                                                                                    "type": "MoneyV2",
                                                                                                    "keyRaw": "price",

                                                                                                    "selection": {
                                                                                                        "fields": {
                                                                                                            "amount": {
                                                                                                                "type": "Decimal",
                                                                                                                "keyRaw": "amount",
                                                                                                                "visible": true
                                                                                                            }
                                                                                                        }
                                                                                                    },

                                                                                                    "visible": true
                                                                                                },

                                                                                                "compareAtPrice": {
                                                                                                    "type": "MoneyV2",
                                                                                                    "keyRaw": "compareAtPrice",
                                                                                                    "nullable": true,

                                                                                                    "selection": {
                                                                                                        "fields": {
                                                                                                            "amount": {
                                                                                                                "type": "Decimal",
                                                                                                                "keyRaw": "amount",
                                                                                                                "visible": true
                                                                                                            }
                                                                                                        }
                                                                                                    },

                                                                                                    "visible": true
                                                                                                }
                                                                                            }
                                                                                        },

                                                                                        "visible": true
                                                                                    }
                                                                                }
                                                                            },

                                                                            "visible": true
                                                                        }
                                                                    }
                                                                },

                                                                "visible": true
                                                            },

                                                            "title": {
                                                                "type": "String",
                                                                "keyRaw": "title",
                                                                "visible": true
                                                            },

                                                            "tags": {
                                                                "type": "String",
                                                                "keyRaw": "tags",
                                                                "visible": true
                                                            },

                                                            "totalInventory": {
                                                                "type": "Int",
                                                                "keyRaw": "totalInventory",
                                                                "nullable": true,
                                                                "visible": true
                                                            },

                                                            "publishedAt": {
                                                                "type": "DateTime",
                                                                "keyRaw": "publishedAt",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "cursor": {
                                                    "type": "String",
                                                    "keyRaw": "cursor",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "pageInfo": {
                                        "type": "PageInfo",
                                        "keyRaw": "pageInfo",

                                        "selection": {
                                            "fields": {
                                                "hasNextPage": {
                                                    "type": "Boolean",
                                                    "keyRaw": "hasNextPage",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "id": "ID",
            "handle": "String",
            "after": "String",
            "country": "CountryCode",
            "language": "LanguageCode",
            "productCount": "Int"
        },

        "types": {}
    },

    "policy": "CacheOrNetwork",
    "partial": false
};

"HoudiniHash=37b6dc43950a2ae3b1d90020b1dc200c059a78c6cd673910f31edeb6e66119aa";