<script lang="ts">
	import {
		CollectionSliderQueryStore,
		type ProductCardMissingInfo$result,
		ProductCardMissingInfoStore
	} from '$houdini';
	import { browser } from '$app/environment';
	import { classes } from '@thearc-hq/ui-kit/utils';
	import type { SliderProps } from '$lib/storyblok/types';
	import ProductCardVertical from '$lib/components/Product/ProductCardVertical.svelte';
	import { createEventDispatcher } from 'svelte';
	import ProductCardGenericSlider from '$lib/components/Slider/ProductCardGenericSlider.svelte';
	import { languageStore } from '$lib/store/language.store';
	import { ensureCountry, ensureLocale } from '$lib/utils/i18n';
	import { mapToNode, transformProductCollectionsToBranch } from '$lib/utils/product';
	import { addSDS, trimSDS } from '$lib/utils/sds';
	import { isNetworkError } from '$lib/utils';
	import { findInTreeLegacy } from '$lib/utils/collectionTree';
	import { page } from '$app/stores';

	export let blok: SliderProps;
	export let productCount = 250;
	export let productCardClassName = '';
	export let showControls = true;
	const collectionStore = new CollectionSliderQueryStore();
	let stores: (
		| {
				product: ProductCardMissingInfo$result | null;
				sku: string;
		  }
		| undefined
	)[] = [];

	let scrollerRef: HTMLElement;
	let isVisible = false;

	const dispatch = createEventDispatcher();

	const fetchProducts = (language: string, country: string) => {
		stores = Array.from({ length: blok.products?.length ?? 0 }).map(() => undefined);
		let i = 0;
		for (const { productOrVariant } of blok.products ?? []) {
			const [handle, sku] = productOrVariant.split('/');
			const missingInfoStore = new ProductCardMissingInfoStore();
			const localI = Number(i);
			missingInfoStore
				.fetch({
					variables: {
						handle: trimSDS(handle),
						sdsHandle: addSDS(handle),
						language: ensureLocale(language),
						country: ensureCountry(country)
					}
				})
				.then(({ data }) => {
					stores = [
						...stores.slice(0, localI),
						{
							product: data,
							sku: sku
						},
						...stores.slice(localI + 1)
					];
					if (stores.every((store) => !!store)) {
						scrollerRef?.scrollTo({
							left: 0,
							behavior: 'smooth'
						});
					}
				})
				.catch((e) => {
					if (isNetworkError(e)) {
						return;
					}
					throw e;
				});
			i++;
		}
	};

	$: if (blok.collection_slug && browser && isVisible) {
		const possibleLegacy = findInTreeLegacy($page.data.collectionTree, blok.collection_slug);
		collectionStore
			.fetch({
				variables: {
					handle: possibleLegacy?.handle || blok.collection_slug,
					language: ensureLocale($languageStore.locale),
					country: ensureCountry($languageStore.country),
					productCount
				}
			})
			.then(() => {
				scrollerRef?.scrollTo({
					left: 0,
					behavior: 'smooth'
				});
			})
			.catch(() => {
				// do nothing
			});
	}

	$: if (browser && !blok.collection_slug && isVisible) {
		fetchProducts($languageStore.locale, $languageStore.country);
	}
</script>

<ProductCardGenericSlider
	bind:scrollerRef
	on:visible={() => {
		isVisible = true;
	}}
	{showControls}
	title={`${blok.title || $collectionStore.data?.collection?.title || ''}`}
>
	{#if $collectionStore.data && blok.collection_slug}
		{#each mapToNode($collectionStore.data?.collection?.products) as product, i}
			<ProductCardVertical
				{...product}
				branch={transformProductCollectionsToBranch(product.collections)}
				sku={product.variants.edges[0].node.sku}
				class={classes(
					productCardClassName ? productCardClassName : 'default-slider-card',
					i > 0 ? 'snap-start' : 'snap-center'
				)}
				imageProps={{
					fetchpriority: 'low',
					loading: 'lazy',
					decoding: 'async'
				}}
				sourceExtra={`Slider ${blok.collection_slug}`}
				on:click={() => dispatch('productClicked', { product })}
			/>
		{/each}
	{:else if $collectionStore.errors}
		<div class="w-full h-full flex items-center justify-center">
			<p class="text-center text-gray-500">Something went wrong</p>
		</div>
	{:else if blok.products}
		{#each stores as item, i}
			{#if item && item?.product && item?.product?.product}
				<ProductCardVertical
					{...item.product.product}
					branch={transformProductCollectionsToBranch(item?.product?.product?.collections)}
					sku={item.sku}
					class={classes(
						productCardClassName ? productCardClassName : 'default-slider-card',
						i > 0 ? 'snap-start' : 'snap-center'
					)}
					imageProps={{
						fetchpriority: 'low',
						loading: 'lazy',
						decoding: 'async'
					}}
					sourceExtra={`Slider ${blok.title || 'Generic'}`}
				/>
			{/if}
		{/each}
	{:else}
		<div class="relative overflow-hidden w-full min-h-[16rem]">
			<div class="absolute h-[16rem] flex">
				{#each Array.from({ length: 10 }) as _}
					<div
						class={classes(
							'mx-2 transition duration-2500 min-h-[16rem]',
							$collectionStore.fetching ? 'opacity-100' : 'opacity-0'
						)}
					>
						<div class="w-96 h-92 bg-gray-200 animate-pulse" />
						<div class="w-72 h-6 bg-gray-200 animate-pulse mt-2" />
						<div class="w-12 h-4 bg-gray-200 animate-pulse mt-2" />
					</div>
				{/each}
			</div>
		</div>
	{/if}
</ProductCardGenericSlider>
